import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEOadvanced"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

import Container from "react-bootstrap/Container"

const NotFoundPage = () => (
  <>
    <SEO
      title="404: Сторінку не знайдено"
      description="Вибачте за незручності, але щось пішло не так і ви потрапили на сторінку 404"
    />
    <header>
      <Navigation menuVariant="light" />
      <section className="hero py-5 text-center pagenotfound">
        <Container className="my-5">
          <h1 className="mb-4">Сторінку не знайдено (код:404)</h1>
          <p>Вибачте за незручності, але щось пішло не так і ви потрапили на сторінку 404 (сторінка з такою адресою не знайдена).</p>
          <p>Спробуйте, будь ласка, скористатися головним меню або просто перейдіть на головну сторінку.</p>
          <Link to="/" className="btn btn-danger">Перейти на головну сторінку</Link>
        </Container>
      </section>
    </header>
    <Footer />
  </>
)

export default NotFoundPage

